import { Button, Row, Typography } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router';

import { MenuUnfoldOutlined } from '@ant-design/icons';

import { LOGO, SMALL_LOGO } from '../../constants';
import { RoleLabel } from '../../enum';
import {
  appName,
  isBurns,
  validateAsAdmin,
  validateAsFirstAlertsRole,
  validateAsServiceProviderManagerRole,
} from '../../helpers/utils.helper';
import { useAppSelector } from '../../hooks';
import type { AuthResult, CurrentUser } from '../pages/Login/authSlice';
import SideNavMenu from './sidemenu';

const getText = (currentUser: CurrentUser, authResult: AuthResult) => {
  if (validateAsAdmin(currentUser.role)) {
    return RoleLabel[currentUser.role];
  } else if (validateAsServiceProviderManagerRole(currentUser.role)) {
    return authResult.service_provider_organization_name;
  } else {
    return authResult.org_name;
  }
};

const getImg = (isFirstAlert: boolean, collapsed: boolean) => {
  if (isFirstAlert) {
    return `assets/first-alerts-${collapsed ? 'icon' : 'logo'}.svg`;
  } else {
    return collapsed ? SMALL_LOGO.src : LOGO.src;
  }
};

const SideNav = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const authResult = useAppSelector((state) => state.authSlice.authResult);
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);
  const isFirstAlerts = validateAsFirstAlertsRole(currentUser.role);

  return (
    <>
      <Row align="middle" justify="start" wrap={false} style={{ height: 60 }}>
        <Button className="sideMenuBtn" onClick={() => setCollapsed(!collapsed)}>
          <MenuUnfoldOutlined />
        </Button>
        {!collapsed ? (
          <Typography.Text className="side-bar-text-animated" strong>
            {getText(currentUser, authResult)}
          </Typography.Text>
        ) : null}
      </Row>
      <Row align="middle" justify="center" style={{ height: 60 }}>
        <Link to="/">
          <img
            src={getImg(isFirstAlerts, collapsed)}
            alt={`${isFirstAlerts ? 'FirstAlerts' : appName} logo`}
            style={{ height: !collapsed && isBurns ? 72 : 36 }}
          />
        </Link>
      </Row>
      <SideNavMenu />
    </>
  );
};

export default SideNav;
