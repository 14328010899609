import { useState } from 'react';
import { Col, Drawer, Row, Typography } from 'antd';
import { Link } from 'react-router';

import { MenuUnfoldOutlined } from '@ant-design/icons';

import { RoleLabel } from '../../enum';
import { validateAsFirstAlertsRole } from '../../helpers/utils.helper';
import { useAppSelector } from '../../hooks';
import SideNavMenu from './sidemenu';

const HeaderComponent = () => {
  const orgName = useAppSelector((state) => state.authSlice.authResult.org_name);
  const currentUserRole = useAppSelector((state) => state.authSlice.currentUser.role);
  const isFirstAlerts = validateAsFirstAlertsRole(currentUserRole);
  const [visible, setVisible] = useState(false);
  const logo = isFirstAlerts ? 'assets/first-alerts-logo.svg' : 'assets/talkingstick_logo_full_1200.png';
  const logoAlt = `${isFirstAlerts ? 'FirstAlerts' : 'Talking Stick'} logo`;
  const linkTo = isFirstAlerts ? '/page/alerts' : '/page/home';

  return (
    <div>
      <Row justify="end">
        <Col flex="auto">
          <Link to={linkTo}>
            <img src={logo} alt={logoAlt} style={{ width: 'auto', height: 31, padding: 2, marginLeft: 20 }} />
          </Link>
        </Col>

        <Col>
          <Row align="middle">
            <Typography.Text strong> {orgName ? orgName : RoleLabel[currentUserRole]}</Typography.Text>
            <MenuUnfoldOutlined className="menuTriggerMobile" onClick={() => setVisible(true)} />
          </Row>
        </Col>
      </Row>
      <Drawer
        title={
          <Link to={linkTo}>
            <img src={logo} alt={logoAlt} style={{ width: 'auto', height: 28, padding: 2 }} />
          </Link>
        }
        placement="left"
        closable={true}
        className="mobileDrawer"
        open={visible}
        onClose={() => setVisible(false)}
      >
        <div>
          <SideNavMenu />
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderComponent;
