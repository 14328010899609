import { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import { LogoutOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { logout } from '../pages/Login/authSlice';
import { getMenuItems } from './layouts.helper';

const SideNavMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/page')[1];
  const dispatch = useAppDispatch();
  const authResult = useAppSelector((state) => state.authSlice.authResult);
  const currentUser = useAppSelector((state) => state.authSlice.currentUser);
  const [openKeys, setOpenKeys] = useState(['AdminSubMenu']);

  const logoutPrompt = () => {
    Modal.confirm({
      title: 'Log out',
      icon: <LogoutOutlined />,
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(logout());
        navigate('/login');
      },
    });
  };

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const [, lastOpen] = openKeys;
    setOpenKeys([lastOpen]);
  };

  return (
    <Menu
      style={{ marginTop: 15, borderRight: 0 }}
      selectedKeys={[path]}
      theme="light"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={getMenuItems(currentUser, authResult)}
      onSelect={({ key }) => {
        if (key === '/logout') {
          logoutPrompt();
        } else {
          navigate(`/page${key}`, { state: { prevPath: location.pathname } });
        }
      }}
    />
  );
};

export default SideNavMenu;
