import type { ReactNode } from 'react';
import { Navigate } from 'react-router';

import { PageHeader } from '@ant-design/pro-layout';

import type { Role } from '../enum';
import { validateAsFirstAlertsRole, validateAsServiceProviderManagerRole } from '../helpers/utils.helper';
import { useAppSelector } from '../hooks';

const RequireAuth = ({ requiredRoles, children }: { requiredRoles: Role[]; children: ReactNode }) => {
  const authResult = useAppSelector((state) => state.authSlice.authResult);
  const currentUserRole = useAppSelector((state) => state.authSlice.currentUser.role);
  const userHasRequiredRole = requiredRoles.includes(currentUserRole);
  const isFirstAlerts = validateAsFirstAlertsRole(currentUserRole);
  const isServiceProviderManager = validateAsServiceProviderManagerRole(currentUserRole);

  if (authResult.token && userHasRequiredRole) {
    return children;
  } else if (isFirstAlerts) {
    return <Navigate to="/page/alerts" />;
  } else if (isServiceProviderManager) {
    return <Navigate to="/page/admin-service-providers" />;
  } else if (authResult.token) {
    return (
      <PageHeader ghost={false} title="Page not found">
        <p>Please select a page from the navigation bar on the left.</p>
      </PageHeader>
    );
  }

  return <Navigate to="/login" />;
};

export default RequireAuth;
